@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Sofia Pro";
  src: url("./assets/font/sofia-pro/SofiaProRegularAz.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Sofia Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: Sofia Pro;
}
/* .fontAnimation{
  white-space: pre-wrap;
  height: 210px;
  max-width: 100%;
  overflow: hidden;
  animation: animate 0.5s steps(20,end) forwards;
} */
@keyframes animate {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (min-width: 1024px) {
  body {
    overflow: hidden;
  }
}

@media (max-width: 1023px) {
  body {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
